import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";



function Footer() {
    return (
        <footer id="footer" className="footer d-flex">
          <div className="container">
            <div className="float-left copyright">
              &copy; 2025
              <a className="juni-copy" href="https://www.junifeup.pt" target="_blank" rel="noreferrer"> JuniFEUP</a>
            </div>
            <div className="social-area">
              <a className="linkedin-icon" href="https://www.linkedin.com/company/junifeup/mycompany/"><FaLinkedinIn/></a>
              <a className="facebook-icon" href="https://www.facebook.com/junifeup/"><FaFacebookF/></a>
              <a className="instagram-icon" href="https://www.instagram.com/junifeup/"><FaInstagram /></a>
            </div>
          </div>
      </footer>
    );
  }
  
  export default Footer;